var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header",
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "id": "listing-header"
    }
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Listings")))]), _c('AddNewButton')], 1), _c('v-row', {
    staticStyle: {
      "padding-left": "104px",
      "margin-top": "0px"
    }
  }, [_c('div', {
    class: _vm.activeTab === 'listings' ? 'tab-selected content-small' : 'tab-unselected content-small',
    on: {
      "click": _vm.activelistingsView
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ActiveListings")) + " ")]), _c('div', {
    class: _vm.activeTab === 'archives' ? 'tab-selected content-small' : 'tab-unselected content-small',
    staticStyle: {
      "margin-left": "24px"
    },
    on: {
      "click": _vm.archiveView
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Archive")) + " ")])]), _vm.loaded ? _c('HeaderToolbar', {
    attrs: {
      "module": 'listing'
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "flex-wrap": "wrap",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important"
    },
    attrs: {
      "name": "add-listing-button"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/listings/add');
      }
    }
  }, [_c('img', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    },
    attrs: {
      "src": require("../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddListing")) + " ")])]), _c('FilterDropdown', {
    attrs: {
      "from-dashboard": _vm.savedViewId,
      "filter-name": "listings"
    },
    on: {
      "currentSelectedFilter": function ($event) {
        return _vm.updateAdvancedFilters($event);
      }
    }
  }), _c('div', {
    staticStyle: {
      "margin-left": "0px"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('DropdownMulti', {
    attrs: {
      "items": _vm.dealTypes,
      "display-key": 'name',
      "value-key": 'value',
      "height": '34px',
      "font-size": '12px',
      "default-value": _vm.$t('AllListings')
    },
    model: {
      value: _vm.selectedDeals,
      callback: function ($$v) {
        _vm.selectedDeals = $$v;
      },
      expression: "selectedDeals"
    }
  }), _c('DropdownMulti', {
    attrs: {
      "items": _vm.estateTypes,
      "display-key": 'name',
      "value-key": 'value',
      "height": '34px',
      "font-size": '12px',
      "default-value": _vm.$t('AllEstateTypes')
    },
    model: {
      value: _vm.selectedEstate,
      callback: function ($$v) {
        _vm.selectedEstate = $$v;
      },
      expression: "selectedEstate"
    }
  }), _c('DropdownMulti', {
    attrs: {
      "items": _vm.statusTypes,
      "display-key": 'name',
      "value-key": 'value',
      "height": '34px',
      "font-size": '12px',
      "default-value": _vm.$t('AllStatuses')
    },
    model: {
      value: _vm.selectedStatuses,
      callback: function ($$v) {
        _vm.selectedStatuses = $$v;
      },
      expression: "selectedStatuses"
    }
  }), _c('GroupOrUserSelect', {
    model: {
      value: _vm.chosenUsers,
      callback: function ($$v) {
        _vm.chosenUsers = $$v;
      },
      expression: "chosenUsers"
    }
  }), _c('div', {
    staticStyle: {
      "cursor": "pointer",
      "margin-left": "4px",
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap"
    },
    on: {
      "click": _vm.openExtraFilters
    }
  }, [_c('p', {
    staticClass: "link-small",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Filters")) + " ")]), _c('img', {
    class: {
      up: _vm.filtersOpen,
      down: !_vm.filtersOpen
    },
    staticStyle: {
      "width": "16px",
      "height": "16px",
      "margin-left": "8px",
      "cursor": "pointer",
      "align-self": "center"
    },
    attrs: {
      "src": require("../../assets/images/chevron.svg"),
      "alt": ""
    }
  })])], 1), _c('hr'), _c('AdvancedFilters', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filtersOpen,
      expression: "filtersOpen"
    }],
    key: 'advanced-filters-' + _vm.toolbarUpdater,
    attrs: {
      "filter-data": _vm.filterData,
      "chosen-broker": _vm.chosenBrokerId,
      "deal-types": _vm.selectedDealTypes,
      "active-tab": _vm.activeTab,
      "estate-types": _vm.selectedEstateTypes,
      "past-day-options": _vm.pastDayOptions,
      "water-mark-options": _vm.waterMarkOptions
    },
    on: {
      "filtersReset": _vm.clearFilters
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }