<template>
  <div class="content">
    <ListContent
      :listing-table-updater="listingTableUpdater"
      :listings="listings"
      :selected-listings-list="selectedlistings"
      :active-filters="activeFilters"
      :conditionsObj="conditionsObj"
      :total-count="totalListings"
      :statisticsWidgetStatus="statisticsWidgetStatus"
      :selectedBrokerId="selectedBrokerId"
      @sortListings="sortListings"
      @boardLeadsUpdated="$emit('boardLeadsUpdated')"
      @widget-status="widgetStatus"
    />
  </div>
</template>

<script>
import ListContent from "@/components/Listings/ListContent";
export default {
  name: "Content",
  components: {
    ListContent,
  },
  props: [
    "listings",
    "selectedListingsList",
    "activeTab",
    "activeFilters",
    "listingTableUpdater",
    "conditionsObj",
    "statisticsWidgetStatus",
    "selectedBrokerId",
    "totalListings"
  ],
  watch: {
    selectedListingsList: function () {
      this.selectedlistings = this.selectedListingsList;
    },
    filtersOpen: function () {
      this.filtersListOpen = this.filtersOpen;
    },
  },
  data() {
    return {
      selectedlistings: this.selectedListingsList,
      filtersListOpen: this.filtersOpen,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    sortListings(data) {
      this.$emit("sortListings", data);
    },
    usableListings(value) {
      this.$emit("listings", value);
    },
    updateOffersCounter() {
      this.$emit("updateOffersCounter");
    },
    widgetStatus(status) {
      this.$emit("widget-status", status);
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  padding: 0;
}
.content {
  display: block;
  padding-left: 104px;
  min-height: 980px;
  background-color: #f4f5f7;
}
.customer-row {
  margin-bottom: 8px;
  background-color: transparent;
}
.tag {
  padding: 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
}
.tag:before {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #0aaf60;
}
.title {
  width: 100%;
}
img,
p {
  display: inline-block;
}
img {
  float: left;
}
.gray {
  background: #f4f5f7;
}
.green {
  background: #0aaf60;
  color: white;
}
.orange {
  background: #ff5c01;
  color: white;
}
.object-row {
  background: #ffff;
  border-radius: 8px;
  padding: 18px;
  min-width: 1050px !important;
  width: 100%;
  margin-top: 16px;
  .img-col {
    cursor: pointer;
    overflow: hidden;
    min-height: 120px;
    min-width: 250px;
    height: unset;
    width: 250px;
    img {
      border-radius: 8px;
      //width: 100%;
      //height: unset;
    }
  }
  .info-col {
    padding-left: 16px;
    .listing-title {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .info-icon {
      width: 24px;
      height: 24px;
    }
    .info-text {
      padding-left: 8px;
    }
  }
  img {
    height: auto;
    width: 100%;
  }
}
.relation:hover {
  cursor: pointer;
  color: dodgerblue;
}
.tags-row {
  margin-top: 16px;
  .booking {
    margin-bottom: 0 !important;
    padding-top: 4px;
  }
}
.tags {
  p {
    margin-bottom: 0 !important;
  }
  .active {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-image: url("../../assets/images/green-circle.svg");
  }
  .tag-wrapper {
    align-items: center;
  }
}

.button-col {
  display: flex;
  justify-content: flex-end;
  .v-btn {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    min-width: 10px !important;
  }
}
</style>
