<template>
  <vue-draggable-resizable
      :z="3"
      style="position: fixed"
      :resizable="false"
      :x="posX"
      :y="posY"
      drag-handle=".drag-area"
      id="widget-overlay"
      class="widget-overlay"
      @dragstop="onDragStop"
      :style="extended && !inTopArea ? 'top: calc(100vh - 399px)' : null"
  >
    <div id="widget">
      <div class="drag-area">
        <img src="../../assets/images/menu.svg" alt="" />
      </div>
      <div class="statistics-header" @click="revertExtension">
        <img
          :src="
            extended
              ? require('../../assets/images/collapse-down.svg')
              : require('../../assets/images/collapse-up.svg')
          "
          alt=""
          class="statistics-chevron"
        />
        <h5 class="statistics-header-text" v-if="!extended">
          {{ totalListingsCount }} {{ $t("Listingst") }}
      
        </h5>
        <h5 class="statistics-header-text"  v-if="extended && !mainInfoLoaded">
          {{ totalListingsCount }} {{ $t("Listingst") }}
        </h5>
        <h5 class="statistics-header-text"  v-if="extended && mainInfoLoaded">
          {{ statistics.listingsTotal.total }} {{ $t("Listingst") }}
        </h5>
      </div>
     
      <div v-if="extended" class="statistics-content" style="display: flex;justify-content: center; min-width: 290px">
       
           <div
            v-if="!mainInfoLoaded"
            style="
              width: 50px;
              height: 50px;
            "
            class="loader"
        />
        <div v-if="mainInfoLoaded">
        <div class="statistics-sites-info">
          <div>
            <div class="statistics-site-label">KV</div>
            <div class="statistics-site-numbers">
              {{ statistics.kv.quantity }} / {{ statistics.kv.total }}
            </div>
          </div>
          <div>
            <div class="statistics-site-label">CITY24</div>
            <div class="statistics-site-numbers">
              {{ statistics.city24.quantity }} / {{ statistics.city24.total }}
          </div>
          </div>
          <div>
            <div class="statistics-site-label">KV24</div>
            <div class="statistics-site-numbers">
              {{ statistics.kv24.quantity }} / {{ statistics.kv24.total }}
            </div>
          </div>
          <div>
            <div class="statistics-site-label">WWW</div>
            <div class="statistics-site-numbers">
              {{ statistics.www.quantity }} / {{ statistics.www.total }}
            </div>
          </div>
        </div>

        <div class="line" />

        <div class="statistics-estate-info">
          <span class="statistics-estate-con">
            <h6 class="statistics-estate-label">
              {{ $t("Apartment") }}
            </h6>
            <div class="statistics-estate-numbers">
              <div>
                <div class="statistics-site-label">
                  {{ $t("Sale") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.apartment.sale }}
                </div>
              </div>
              <div>
                <div class="statistics-site-label">
                  {{ $t("Rent") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.apartment.rent }}
                </div>
              </div>
            </div>
          </span>
          <span class="statistics-estate-con">
            <h6 class="statistics-estate-label">
              {{ $t("House") }}
            </h6>
            <div class="statistics-estate-numbers">
              <div>
                <div class="statistics-site-label">
                  {{ $t("Sale") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.house.sale }}
                </div>
              </div>
              <div>
                <div class="statistics-site-label">
                  {{ $t("Rent") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.house.rent }}
                </div>
              </div>
            </div>
          </span>
          <span class="statistics-estate-con">
            <h6 class="statistics-estate-label">
              {{ $t("Land") }}
            </h6>
            <div class="statistics-estate-numbers">
              <div>
                <div class="statistics-site-label">
                  {{ $t("Sale") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.land.sale }}
                </div>
              </div>
              <div>
                <div class="statistics-site-label">
                  {{ $t("Rent") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.land.rent }}
                </div>
              </div>
            </div>
          </span>
          <span class="statistics-estate-con">
            <h6 class="statistics-estate-label">
              {{ $t("Commercial") }}
            </h6>
            <div class="statistics-estate-numbers">
              <div>
                <div class="statistics-site-label">
                  {{ $t("Sale") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.commercial.sale }}
                </div>
              </div>
              <div>
                <div class="statistics-site-label">
                  {{ $t("Rent") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.commercial.rent }}
                </div>
              </div>
            </div>
          </span>
          <span class="statistics-estate-con">
            <h6 class="statistics-estate-label">
              {{ $t("HousePart") }}
            </h6>
            <div class="statistics-estate-numbers">
              <div>
                <div class="statistics-site-label">
                  {{ $t("Sale") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.house_part.sale }}
                </div>
              </div>
              <div>
                <div class="statistics-site-label">
                  {{ $t("Rent") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.house_part.rent }}
                </div>
              </div>
            </div>
          </span>
          <span class="statistics-estate-con">
            <h6 class="statistics-estate-label">
              {{ $t("Cottage") }}
            </h6>
            <div class="statistics-estate-numbers">
              <div>
                <div class="statistics-site-label">
                  {{ $t("Sale") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.cottage.sale }}
                </div>
              </div>
              <div>
                <div class="statistics-site-label">
                  {{ $t("Rent") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.cottage.rent }}
                </div>
              </div>
            </div>
          </span>
          <span class="statistics-estate-con">
            <h6 class="statistics-estate-label">
              {{ $t("Garage") }}
            </h6>
            <div class="statistics-estate-numbers">
              <div>
                <div class="statistics-site-label">
                  {{ $t("Sale") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.garage.sale }}
                </div>
              </div>
              <div>
                <div class="statistics-site-label">
                  {{ $t("Rent") }}
                </div>
                <div class="statistics-site-numbers">
                  {{ statistics.garage.rent }}
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import "vue-date-pick/dist/vueDatePick.css";
import { mapActions, mapGetters } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";
import listingApi from "@/http/listing";

export default {
  name: "StatisticsPanel",
  components: {
    VueDraggableResizable,
  },
  props: [
    "position",
    "selectedBrokerId",
    "conditionsObj",
    "statisticsWidgetStatus",
    "listingTableUpdater",
    "totalListingsCount"
  ],
  data() {
    return {
      listings: [],
      extended: false,
      showPublishRow: false,
      statistics: null,
      posX: 0,
      posY: 0,
      inTopArea: false,
      loadDataWhenExtended: false,
      loaded: false,
      mainInfoLoaded: false,
    };
  },
  async created() {
   //await this.setupData()
  },
  mounted() {
    if (this.statisticsWidgetStatus.extended) {
       this.restoreWidgetStatus();
    }
    if(this.statisticsWidgetStatus.posX && this.statisticsWidgetStatus.posY) {
      this.posX = this.statisticsWidgetStatus.posX;
      this.posY = this.statisticsWidgetStatus.posY;
    }
  },
  watch: {
    'listingTableUpdater': function() {
      if (this.statisticsWidgetStatus && this.statisticsWidgetStatus.extended) {
        this.setupData()
      } 
    },
    'statisticsWidgetStatus': function() {
      if (this.statisticsWidgetStatus && this.statisticsWidgetStatus.extended) {
          this.setupData()
      }
    },
      //this.initData()
    
  },

  computed: {
    ...mapGetters([
      "users",
      "activeTab",
        "user",
    ]),
  },
  methods: {
    async setupData() {
      this.mainInfoLoaded = false
      //if (!this.users) await this.getUsers();
   /*    if (this.activeTab === "archives") {
        this.statistics = await listingApi.getListingsWidgetDataArchived(
            this.conditionsObj
        );
        this.loaded = true;
      } else { */
        this.conditionsObj.selectedBrokerId = this.selectedBrokerId;
        this.statistics = await listingApi.getListingsWidgetData(
            this.conditionsObj
        );
        this.mainInfoLoaded = true
     /*  } */

    },
    isInTopArea() {
      let widgetHeight = document.getElementById("widget").clientHeight;
      let difference = this.posY + widgetHeight;

      if (difference < 0) {
        this.inTopArea = true;
      } else {
        this.inTopArea = false;
      }
    },
    onDragStop(left, top) {
      this.posX = left;
      this.posY = top;

      let widgetStatus = {
        extended: this.extended,
        posX: left,
        posY: top,
      };
      this.$parent.$emit("widget-status", widgetStatus);

    },
    ...mapActions([
      "getUsers",
    ]),
    async initData() {
      await this.setupData()
      //this.statistics = this.getEmptyStatistics();

     /*  this.initSitesTotals();
      this.initListingsStatistics(); */
    },
   /*  initListingsStatistics() {
      this.listings.forEach((listing) => {
        this.mapSitesQuantity(listing);
        this.mapEstateType(listing);
      });
    }, */
  /*   mapEstateType(listing) {
      if (listing.dealType === "short_term_rent") {
        this.statistics[listing.estateType]["rent"]++;
      }
      if (
        listing.estateType &&
        listing.dealType &&
        (listing.dealType === "rent" || listing.dealType === "sale")
      ) {
        this.statistics[listing.estateType][listing.dealType]++;
      }
    }, */
    /* mapSitesQuantity(listing) {
      let listingStatus = listing.status;
      let marketplaces = listing.marketplaces;
      let broker;
      if (!this.selectedBrokerId){
        broker = this.user;
      } else {
        broker = this.users.find((broker) => {
          return broker._id === this.selectedBrokerId;
        });
      }
      for (const marketplace of marketplaces) {
        if(broker) {
          if (
              marketplace.isActive &&
              listingStatus !== "draft" &&
              listingStatus !== "archived" &&
              listingStatus !== "expired" &&
              listingStatus !== "sold" &&
              listingStatus !== "rented" &&
              (marketplace.activeUser === broker.email ||
                  marketplace.activeUser === broker.listingAccounts[0].email ||
                  marketplace.activeUser === "" ||
                  !marketplace.activeUser)
          ) {
            this.statistics[marketplace.name].quantity++;
          }
        } else {
          if (
              marketplace.isActive &&
              listingStatus !== "draft" &&
              listingStatus !== "archived" &&
              listingStatus !== "expired" &&
              listingStatus !== "sold" &&
              listingStatus !== "rented"
          ) {
            this.statistics[marketplace.name].quantity++;
          }
        }
      }
    }, */
   /*  initSitesTotals() {
      if (this.selectedBrokerId && this.selectedBrokerId !== "allBrokers") {
        this.showPublishRow = true;
        const user = this.users.find(
            (user) => user._id === this.selectedBrokerId
        );
        if (user) {
          this.statistics.kv.total = user.kvTotal;
          this.statistics.kv24.total = user.kv24Total;
          this.statistics.city24.total = user.city24Total;
          this.statistics.www.total = user.wwwTotal;
        } else {
          let groupUsers = [];
          this.users.forEach(
              (user) => user.groups.forEach((group) => {
                if(group._id === this.selectedBrokerId) {
                  groupUsers.push(user)
                }})
          )
          if (groupUsers.length > 0) {
            groupUsers.forEach((user) => {
              this.statistics.kv.total += user.kvTotal;
              this.statistics.kv24.total += user.kv24Total;
              this.statistics.city24.total += user.city24Total;
              this.statistics.www.total += user.wwwTotal;
            });
          }
        }
      } else {
        this.showPublishRow = false;
        this.users.forEach((user) => {
          this.statistics.kv.total += user.kvTotal;
          this.statistics.kv24.total += user.kv24Total;
          this.statistics.city24.total += user.city24Total;
          this.statistics.www.total += user.wwwTotal;
        });
      }
    }, */
    revertExtension() {
      this.extended = !this.extended;
      if (!this.statistics || this.loadDataWhenExtended) this.setupData();
      this.$nextTick(() => {
        this.isInTopArea();
      });

      let widgetStatus = {
        extended: this.extended,
        posX: this.posX,
        posY: this.posY,
      };
      this.$parent.$emit("widget-status", widgetStatus);
    },
    restoreWidgetStatus() {
      this.extended = this.statisticsWidgetStatus.extended;
      if (!this.statistics || this.loadDataWhenExtended) {
        this.setupData()
      }
      this.$nextTick(() => {
        this.isInTopArea();
      });
    },
    getEmptyStatistics() {
      return {
        kv: {
          quantity: 0,
          total: 0,
        },
        kv24: {
          quantity: 0,
          total: 0,
        },
        city24: {
          quantity: 0,
          total: 0,
        },
        www: {
          quantity: 0,
          total: 0,
        },
        apartment: {
          sale: 0,
          rent: 0,
        },
        house: {
          sale: 0,
          rent: 0,
        },
        land: {
          sale: 0,
          rent: 0,
        },
        commercial: {
          sale: 0,
          rent: 0,
        },
        house_part: {
          sale: 0,
          rent: 0,
        },
        cottage: {
          sale: 0,
          rent: 0,
        },
        garage: {
          sale: 0,
          rent: 0,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.widget-overlay {
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: end;
  //align-items: flex-end;
  top: 85%;
  //bottom: 0;
  right: 0;
  padding-bottom: 20px;
  padding-right: 40px;
}

#widget {
  width: max-content;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  position: fixed;
  max-width: 298px;
  background: black;
  color: white !important;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}

.statistics-site-numbers {
  font-weight: 600;
}

.statistics-site-label {
  color: $grey800;
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 4px;
}

.statistics-estate-info {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.statistics-estate-con {
  margin-right: 8px;
}

.statistics-estate-label {
  color: white !important;
  margin-bottom: 8px;
}

.statistics-estate-numbers {
  display: flex;
  gap: 16px;
}

.line {
  height: 1px;
  background: $grey950;
  margin: 16px 0;
}

.statistics-sites-info {
  display: flex;
  gap: 24px;
}

.statistics-header {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px;
  cursor: pointer;
}

.statistics-chevron {
  margin-left: 2px;
  filter: invert(1);
}

.statistics-header-text {
  color: white !important;
  white-space: nowrap;
}

.statistics-content {
  padding: 0 16px 16px 16px;
}
.drag-area {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  border-radius: 8px;
  padding: 4px;
  img {
    cursor: pointer;
  }
}
</style>
