<template>
  <v-row class="customer-row">
    <div
      v-show="!listings"
      class="loader"
      style="position: absolute; top: 50%; left: 50%"
    ></div>

    <div v-if="listingsNone || (usableListings && usableListings.length < 1)">
      <p v-if="listingsNone" class="heading-geo32 no-results-listing">
        {{ $t(listingsNone) }}
      </p>
      <p v-else class="heading-geo32 no-results-listing">
        {{ $t("NoResultsFound") }}
      </p>
      <p
        class="clear-filters-button"
        @click="clearFilters"
        v-if="
          listingsNone === 'NoResultsFound' ||
          (!listingsNone && usableListings && usableListings.length < 1)
        "
      >
        {{ $t("ClearAllFilters") }}
      </p>
    </div>

    <v-row
      v-show="!listingsNone && usableListings && usableListings.length > 0"
    >
      <v-row class="listing-title">
        <v-row class="checkbox-wrapper">
          <Checkbox
            @clicked="handleAllListingSelections()"
            :checked="allListingsSelected"
          >
          </Checkbox>
        </v-row>
        <v-row class="address-wrapper">
          <p
            :class="{
              'bold-weight': sortCounters[5]['listingName'] !== 1,
              'normal-weight': sortCounters[5]['listingName'] === 1,
            }"
            class="title-items"
            @click="sortByStringValue('listingName')"
          >
            {{ $t("Address") }}
          </p>
          <img
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[5]['listingName'] === 2,
              down: sortCounters[5]['listingName'] === 3,
              hidden: sortCounters[5]['listingName'] === 1,
            }"
          />
        </v-row>
        <v-row class="city-wrapper">
          <p
            :class="{
              'bold-weight': sortCounters[6]['city'] !== 1,
              'normal-weight': sortCounters[6]['city'] === 1,
            }"
            class="title-items"
            @click="sortByStringValue('city')"
          >
            {{ $t("City") }}
          </p>
          <img
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[6]['city'] === 2,
              down: sortCounters[6]['city'] === 3,
              hidden: sortCounters[6]['city'] === 1,
            }"
          />
        </v-row>
        <v-row class="listing-type-wrapper">
          <p
            :class="{
              'bold-weight': sortCounters[3]['dealType'] !== 1,
              'normal-weight': sortCounters[3]['dealType'] === 1,
            }"
            class="title-items"
            @click="sortByStringValue('dealType')"
          >
            {{ $t("ListingType") }}
          </p>
          <img
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[3]['dealType'] === 2,
              down: sortCounters[3]['dealType'] === 3,
              hidden: sortCounters[3]['dealType'] === 1,
            }"
          />
        </v-row>
        <v-row class="estate-type-wrapper">
          <p
            v-if="this.$i18n.locale == 'en'"
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[4]['estateType'] !== 1,
              'normal-weight': sortCounters[4]['estateType'] === 1,
            }"
            class="title-items"
            @click="sortByStringValue('estateType')"
          >
            {{ $t("RealEstateType") }}
          </p>
          <img
            v-if="this.$i18n.locale == 'en'"
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[4]['estateType'] === 2,
              down: sortCounters[4]['estateType'] === 3,
              hidden: sortCounters[4]['estateType'] === 1,
            }"
          />
          <p
            v-if="this.$i18n.locale == 'et'"
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[4]['estateType'] !== 1,
              'normal-weight': sortCounters[4]['estateType'] === 1,
            }"
            class="title-items"
            @click="sortByStringValue('estateType')"
          >
            {{ $t("RealEstateType") }}
          </p>
          <img
            v-if="this.$i18n.locale == 'et'"
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[4]['estateType'] === 2,
              down: sortCounters[4]['estateType'] === 3,
              hidden: sortCounters[4]['estateType'] === 1,
            }"
          />
        </v-row>
        <v-row class="rooms-wrapper">
          <p
            v-if="this.$i18n.locale == 'en'"
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[2]['rooms'] !== 1,
              'normal-weight': sortCounters[2]['rooms'] === 1,
            }"
            class="title-items"
            @click="sortByNumericalValue('rooms')"
          >
            {{ $t("Rooms") }}
          </p>
          <img
            v-if="this.$i18n.locale == 'en'"
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[2]['rooms'] === 2,
              down: sortCounters[2]['rooms'] === 3,
              hidden: sortCounters[2]['rooms'] === 1,
            }"
          />

          <p
            v-if="this.$i18n.locale == 'et'"
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[2]['rooms'] !== 1,
              'normal-weight': sortCounters[2]['rooms'] === 1,
            }"
            class="title-items"
            @click="sortByNumericalValue('rooms')"
          >
            {{ $t("Rooms") }}
          </p>
          <img
            v-if="this.$i18n.locale == 'et'"
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[2]['rooms'] === 2,
              down: sortCounters[2]['rooms'] === 3,
              hidden: sortCounters[2]['rooms'] === 1,
            }"
          />
        </v-row>
        <v-row class="size-wrapper">
          <p
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[1]['generalSurfaceArea'] !== 1,
              'normal-weight': sortCounters[1]['generalSurfaceArea'] === 1,
            }"
            class="title-items"
            @click="sortByNumericalValue('generalSurfaceArea')"
          >
            {{ $t("SizeMeter") }}
          </p>
          <img
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[1]['generalSurfaceArea'] === 2,
              down: sortCounters[1]['generalSurfaceArea'] === 3,
              hidden: sortCounters[1]['generalSurfaceArea'] === 1,
            }"
          />
        </v-row>
        <v-row class="price-wrapper" style="margin-right: 8px">
          <p
            v-if="this.$i18n.locale == 'en'"
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[0]['price'] !== 1,
              'normal-weight': sortCounters[0]['price'] === 1,
            }"
            class="title-items"
            id="price"
            @click="sortByNumericalValue('price')"
          >
            {{ $t("PriceEur") }}
          </p>
          <img
            v-if="this.$i18n.locale == 'en'"
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[0]['price'] === 2,
              down: sortCounters[0]['price'] === 3,
              hidden: sortCounters[0]['price'] === 1,
            }"
          />
          <p
            v-if="this.$i18n.locale == 'et'"
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[0]['price'] !== 1,
              'normal-weight': sortCounters[0]['price'] === 1,
            }"
            class="title-items"
            id="price"
            @click="sortByNumericalValue('price')"
          >
            {{ $t("PriceEur") }}
          </p>
          <img
            v-if="this.$i18n.locale == 'et'"
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[0]['price'] === 2,
              down: sortCounters[0]['price'] === 3,
              hidden: sortCounters[0]['price'] === 1,
            }"
          />
        </v-row>
        <v-row class="broker-wrapper">
          <p
            class="title-items"
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[8]['broker'] !== 1,
              'normal-weight': sortCounters[8]['broker'] === 1,
            }"
            @click="sortByStringValue('broker')"
          >
            {{ $t("Broker") }}
          </p>
          <img
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[8]['broker'] === 2,
              down: sortCounters[8]['broker'] === 3,
              hidden: sortCounters[8]['broker'] === 1,
            }"
          />
        </v-row>
        <v-row class="status-wrapper">
          <p
            style="cursor: pointer"
            :class="{
              'bold-weight': sortCounters[7]['status'] !== 1,
              'normal-weight': sortCounters[7]['status'] === 1,
            }"
            class="title-items"
            id="status"
            @click="sortByStringValue('status')"
          >
            {{ $t("Status") }}
          </p>
          <img
            src="../../assets/images/direction=up.svg"
            style="width: 16px; height: 16px"
            alt=""
            v-bind:class="{
              up: sortCounters[7]['status'] === 2,
              down: sortCounters[7]['status'] === 3,
              hidden: sortCounters[7]['status'] === 1,
            }"
          />
        </v-row>
      </v-row>
      <v-row
        style="border-top: 1px solid #e6e8ec; margin-top: 0px; cursor: pointer"
        v-for="(listing, index) in usableListings"
        :key="listing._id"
        class="listing-details"
      >
        <v-row
          :id="'listing-header-row' + listing._id"
          style="min-height: 88px; flex-wrap: nowrap"
        >
          <v-col class="checkbox-column">
            <Checkbox
              @clicked="handleListingSelection(listing)"
              :disabled="!hasAccess(listing) || listing.isNewDevelopment"
              :checked="
                selectedListings.some((selList) => selList._id === listing._id)
              "
            ></Checkbox>
          </v-col>
          <v-col @click="openListing(listing._id)" class="image-column">
            <img
              v-if="listing.images.length > 0"
              style="
                width: 78px;
                height: 52px;
                border-radius: 8px;
                object-fit: cover;
                object-position: center;
              "
              :src="chooseCoverImage(listing.images)"
              alt=""
            />
            <div v-if="!listing.images[0]" class="no-image"></div>
          </v-col>
          <v-col
            @click="openListing(listing._id)"
            :key="openHousesUpdater"
            :class="{
              'address-flex': listing.description || listing.openHouse,
              'address-column': 1,
            }"
          >
            <v-row
              v-if="listing.listingName"
              :class="{
                'address-name': !listing.description && !listing.openHouse,
              }"
              style="width: 200px"
            >
              <p class="listing-item">
                {{ listing.listingName }}
              </p>
            </v-row>
            <v-row
              v-if="listing.description"
              class="listing-desc"
              style="margin-top: 0; width: 180px"
            >
              {{ listing.description }}
            </v-row>
            <v-row style="margin-top: 0">
              <OpenHouseContainer
                v-if="listing.openHouse"
                :task="listing.openHouse"
                :invert="true"
              ></OpenHouseContainer>
            </v-row>
          </v-col>
          <v-col class="city-column" @click="openListing(listing._id)">
            <v-row class="city-wrapper">
              <p class="listing-item">
                {{ listing.city }}
              </p>
            </v-row>
          </v-col>
          <v-col class="listing-type-column" @click="openListing(listing._id)">
            <v-row class="listing-type-wrapper">
              <p class="listing-item">
                {{ formatListingType(listing.dealType) }}
              </p>
            </v-row>
          </v-col>

          <v-col class="estate-type-column" @click="openListing(listing._id)">
            <v-row class="estate-type-wrapper">
              <p class="listing-item">
                {{ $lfMapper.estateType(listing.estateType) }}
              </p>
            </v-row>
          </v-col>
          <v-col class="rooms-column" @click="openListing(listing._id)">
            <v-row class="rooms-wrapper">
              <p v-if="listing.rooms" class="listing-item">
                {{ listing.rooms }}
              </p>
              <p v-if="!listing.rooms" class="listing-item">-</p>
            </v-row>
          </v-col>
          <v-col class="size-column" @click="openListing(listing._id)">
            <v-row class="size-wrapper">
              <p v-if="listing.generalSurfaceArea" class="listing-item">
                {{ listing.generalSurfaceArea }}
              </p>
              <p
                v-else-if="
                  listing.estateType !== 'apartment' &&
                  listing.landArea &&
                  (listing.landArea.m2Selected || listing.landArea.haSelected)
                "
                class="listing-item"
              >
                <span v-if="listing.landArea.m2 && listing.landArea.m2Selected">
                  {{ listing.landArea.m2 }}
                </span>
                <span
                  v-else-if="listing.landArea.ha && listing.landArea.haSelected"
                >
                  {{ listing.landArea.ha * 1000 }}
                </span>
                <span v-else class="listing-item">-</span>
              </p>
              <p v-else class="listing-item">-</p>
            </v-row>
          </v-col>
          <v-col @click="openDropdown(listing)" class="price-column">
            <v-row class="price-wrapper">
              <p
                v-if="listing.price || listing.price === 0"
                class="listing-item"
              >
                {{ listingPrices[index] }}
              </p>
              <p v-else class="listing-item">-</p>
              <div
                v-click-outside="closeDropdown"
                v-if="dropdownIsOpen && listing.priceHistoryLog &&
                listing.priceHistoryLog.length && chosenListing._id === listing._id" 
                class="price-info-container price-info-container-hover"
              >
              <div
                 class="price-history-info-dropdown">
                <div
                  class="price-info"
                  v-for="(info, index) in listing.priceHistoryLog.slice(-20)"
                  :key="'price-info-' + index"
                >
                  <div class="content-normal">
                    {{ formatDate(info.date) }}
                  </div>
                  <div
                    style="width: 100%; text-align: right"
                    class="content-semibold"
                  >
                    {{ $nMapper.price(info.to, "€") }}
                  </div>
                </div>
              </div>
            </div>
            </v-row>
          </v-col>

          <v-col
            class="broker-column"
            @click.stop="
              (event) => openBrokerDetailPanel(event, getListingOwner(listing)._id)
            "
          >
            <v-row class="hover-option">
              {{ getListingOwner(listing).name }}
            </v-row>
            <v-row
              v-if="listing.creator.groups"
              class="content-small"
              style="color: #75787a; margin-top: 8px !important"
            >
              {{ listing.creator.groups }}
            </v-row>
          </v-col>
          <v-col
            v-if="activeTab === 'archives'"
            style="
              display: flex;
              align-items: center;
              flex-grow: 0;
              min-width: 264px;
              max-width: 264px;
              padding-left: 8px;
            "
          >
            <v-row style="min-width: 264px; max-width: 264px; display: flex">
              <p
                :id="'statusLabel' + index"
                class="statusLabel content-bold"
                v-bind:class="{
                  'draft-label ': listing.status === 'draft',
                  'active-label ': listing.status === 'active',
                  'booked-label': listing.status === 'booked',
                  'sold-label ': listing.status === 'sold',
                  'archived-label': listing.status === 'archived' || 'deleted',
                  'rented-label': listing.status === 'rented',
                  'expired-label': listing.status === 'expired',
                }"
              >
                {{ $lfMapper.status(listing.status) }}
              </p>
  
              <div
                v-if="listing.statusCode === 0"
                style="display: flex; justify-content: center; margin-left: 4px"
              >
                <img
                  style="cursor: pointer; margin-left: 4px"
                  src="../../assets/images/trash-redwhite.svg"
                  alt=""
                />
              </div>
            </v-row>
          </v-col>
          <v-col
            v-if="activeTab === 'listings'"
            style="
              flex-grow: 0;
              min-width: 320px;
              max-width: 320px;
              align-self: center;
            "
            :style="
              listing.status === 'draft' || listing.status === 'expired'
                ? 'display: flex'
                : ''
            "
          >
            <v-row
              v-bind:class="{
                'listing-status-button-passive':
                  listing.status === 'draft' || listing.status === 'expired',
                'listing-status-button-active':
                  listing.status === 'active' ||
                  listing.status === 'booked' ||
                  listing.status === 'sold' ||
                  listing.status === 'rented',
              }"
            >
              <p
                @click.stop="openStatusDropdown(listing, index)"
                :id="'statusLabel' + index"
                class="statusLabel content-bold"
                v-bind:class="{
                  'draft-label ': listing.status === 'draft',
                  'active-label ': listing.status === 'active',
                  'booked-label': listing.status === 'booked',
                  'sold-label ': listing.status === 'sold',
                  'archived-label': listing.status === 'archived',
                  'rented-label': listing.status === 'rented',
                  'expired-label': listing.status === 'expired',
                }"
              >
                {{ $lfMapper.status(listing.status) }}
              </p>

              <v-tooltip bottom right :key="bookingUpdater">
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    v-if="listing.status === 'booked'"
                    class="booked-label booked-info-label"
                  >
                    <img
                      style="filter: invert(1)"
                      src="../../assets/images/info.svg"
                      alt=""
                      @click.stop="openBookingDetailsModal(listing)"
                    />
                  </p>
                </template>
                <span>
                  {{ $t("BookingDetails") }}
                </span>
              </v-tooltip>
              <div
                v-if="(listing.status === 'active' || listing.status === 'booked') && listing.city24BrokerError && listing.marketplaces.find(marketplace => marketplace.name === 'city24').isActive && listing.marketplaces.find(marketplace => marketplace.name === 'city24').url"
                style="display: flex; justify-content: center; margin-right: -4px"
                @mouseover="openInfoDropdown(listing)"
                @mouseleave="closeInfoDropdown"
              >
                <img
                  style="cursor: pointer; margin-left: 4px"
                  src="../../assets/images/infoMark20.svg"
                  alt=""
                />

              <div
                v-if="infoDropdownIsOpen && listing.city24BrokerError && chosenListing._id === listing._id" 
                class="info-container info-container-hover"
              >
              <div
                 class="info-dropdown">
                <div
                  class="price-info"
                  style="display: flex; justify-content: center, justify-items: center; align-items: center"
                >
                  <img
                  style="cursor: pointer; width: 24px; height: 24px"
                  src="../../assets/images/warning.svg"
                  alt=""
                />
                  <div class="content-normal">
                    {{ $t("BrokerDontExist") + '! (city24)' }}
                  </div>
                </div>
              </div>
            </div>
              </div>
              <p
                style="margin-left: 8px"
                v-if="
                  listing.status === 'active' &&
                  listing.dateAvailable &&
                  !listing.activeUntil
                "
                class="content-small"
              >
                {{ $t("available") }} {{ formatDate(listing.dateAvailable) }}
              </p>
              <p
                style="margin-left: 8px"
                v-if="listing.status === 'expired' && listing.expiryDate"
                class="content-small"
              >
                {{ $lfMapper.expiryDate(listing.expiryDate) }}
              </p>

              <p
                style="margin-left: 8px"
                @click.stop="openBookingDetailsModal(listing)"
                v-if="listing.status === 'booked' && listing.bookedUntil"
                class="content-small booking-until-date"
              >
                {{ $t("until") }} {{ formatDate(listing.bookedUntil) }}
              </p>

              <date-pick
                :isDateDisabled="isFutureDate"
                @input="changeActiveUntil(listing, $event)"
                v-if="listing.status === 'active' && listing.activeUntil"
                style="display: flex"
              >
                <template v-slot:default="{ toggle }">
                  <p
                    @click.stop="toggle"
                    style="margin-left: 8px"
                    v-if="listing.status === 'active' && listing.activeUntil"
                    class="content-small"
                  >
                    {{ $t("until") }} {{ listing.activeUntil }}
                  </p>
                </template>
              </date-pick>
            </v-row>
            <v-row style="margin-top: 8px">
              <Outputs
                  v-if="listing.status === 'active' || listing.status === 'booked'"
                  @openOutputSettings="(outputPosition) => openOutputsModal(outputPosition, listing)" :listing="listing" />
            </v-row>
          </v-col>
          <v-col v-if="activeTab === 'listings'" class="kanban-button-column">
            <div
              @click.stop="hasAccess(listing)
                  ? collapseBoard(listing._id, false)
                  : null
              "
              class="listing-title-row"
              style="min-width: 98px; max-width: 98px; cursor: pointer"
              :style="
                hasAccess(listing)
                  ? null
                  : 'cursor: default'
              "
            >
              <div :id="listing._id + 'button-container'" class="button-leads">
                <img
                  :id="listing._id + 'down'"
                  src="../../assets/images/collapse-down.svg"
                  alt=""
                  style="height: 13px; width: 13px"
                  :style="
                    hasAccess(listing)
                      ? null
                      : 'filter: invert(95%) sepia(19%) saturate(10%) hue-rotate(314deg) brightness(86%) contrast(96%);'
                  "
                />
                <img
                  style="display: none; height: 13px; width: 13px"
                  :id="listing._id + 'up'"
                  src="../../assets/images/collapse-up.svg"
                  alt=""
                />
                <p
                  :key="leadCounterUpdater"
                  class="leads-number-text"
                  :id="listing._id + 'leadsnum'"
                  :style="
                    hasAccess(listing)
                      ? null
                      : 'color: lightgray'
                  "
                >
                  {{ numberOfLeads(listing) }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <div
          v-if="activeTab === 'listings'"
          :id="'separator' + listing._id"
          class="kanban-separator"
        ></div>
        <div
          name="main-kanban-container"
          style="width: 100%; padding-left: 24px; padding-right: 24px"
        >
          <v-row
            v-if="activeTab === 'listings'"
            style="z-index: 1; margin-bottom: 48px"
            class="kanbanRow"
            :id="'kanbanRow' + listing._id"
          >
            <LeadsKanban
              v-if="openedKanbans.includes(listing._id)"
              :kanban-updater="kanbanUpdater"
              :listing="listing"
              @leadOpened="openKanbanLead"
              @funnelCount="setNumberOfLeads($event, index)"
              style="cursor: auto; width: 100%"
              @refreshLeads="refreshBoardLeads"
            />
          </v-row>
        </div>
      </v-row>
    </v-row>
    <ClientSidepanel
      @panelClosed="leadPanelClosed()"
      @refreshLeads="updateListings"
      @customerUpdated="customerUpdated"
      :customer-data="clickedLead.leadId"
      :customer-data-leads-list="clickedLead.customerId"
      v-if="leadOpen && activeTab === 'listings'"
    />
    <StatusDropdown
      @closed="statusesOpen = false"
      @statusChanged="changeListingStatus"
      @statusesChanged="changeListingsStatuses"
      @open-booking-modal="openBookingDetailsModal"
      v-if="statusesOpen"
      :position="position"
      :listing="selectedListing"
    />
    <StatisticsPanel
      v-if="activeTab !== 'archives'"
      :conditionsObj="conditionsObj"
      :listingTableUpdater="listingTableUpdater"
      :selectedBrokerId="selectedBrokerId"
      :total-listings-count="totalCount"
      :statisticsWidgetStatus="statisticsWidgetStatus"
    />
    <BrokerInfoPanel
      v-if="brokerDetailPanelOpened"
      :item="clickedBroker"
      @closed="closeBrokerDetailPanel"
      :position="brokerPanelPosition"
    />
    <BookingDetailsModal
      v-if="bookingDetailsModalOpen"
      :opened-listing="bookedListing"
      @update-listing="changeListingStatus"
      @close-modal="closeBookingDetailsModal"
    >
    </BookingDetailsModal>
    <OutputsModal
      v-if="clickedListing"
      :position="modalPosition"
      :toggle-modal="toggleOutputsModal"
      :listing="clickedListing"
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LeadsKanban from "../Listings/LeadsKanban";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel";
import StatusDropdown from "@/components/Listings/StatusDropdown";
import Checkbox from "@/components/common/Checkbox";
import axios from "axios";
import OpenHouseContainer from "@/components/common/OpenHouseContainer";
import StatisticsPanel from "@/components/Listings/StatisticsPanel";
import DatePick from "vue-date-pick";
import listingApi from "@/http/listing";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel";
import BookingDetailsModal from "./BookingDetailsModal";
import OutputsModal from "@/components/Listings/OutputsModal2.vue";
import moment from "moment";
import Outputs from "@/components/Listings/Outputs/Outputs";

//import nanoid from "nanoid";

export default {
  name: "ListContent",
  props: [
    "statusValues",
    "selectedListingsList",
    "filtersOpen",
    "activeFilters",
    "changedObject",
    "listings",
    "listingTableUpdater",
    "conditionsObj",
    "statisticsWidgetStatus",
    "selectedBrokerId",
    "totalCount",
  ],
  components: {
    Outputs,
    BookingDetailsModal,
    LeadsKanban,
    ClientSidepanel,
    StatusDropdown,
    Checkbox,
    OpenHouseContainer,
    StatisticsPanel,
    DatePick,
    BrokerInfoPanel,
    OutputsModal,
  },
  data() {
    return {
      clickedListing: null,
      modalPosition: null,
      toggleOutputsModal: false,
      bookingDetailsModalOpen: false,
      openedKanbans: [],
      focusedListing: null,
      bookedListing: null,
      isActive: null,
      boardLeadsUpdater: 0,
      leadCounterUpdater: 0,
      statusesOpen: false,
      brokerDetailPanelOpened: false,
      position: {},
      brokerPanelPosition: null,
      clickedBroker: null,
      selectedListing: null,
      exactListing: null,
      kanbanUpdater: 0,
      leadOpen: false,
      clickedLead: null,
      dropdownIsOpen: false,
      infoDropdownIsOpen: false,
      sortCounters: [
        { price: 1, type: "number" },
        { generalSurfaceArea: 1, type: "number" },
        { rooms: 1, type: "number" },
        { dealType: 1, type: "string" },
        { estateType: 1, type: "string" },
        { listingName: 1, type: "string" },
        { city: 1, type: "string" },
        { status: 1, type: "string" },
        { broker: 1, type: "string" },
      ],
      selectedListings: [],
      allListingsSelected: false,
      originalArr: null,
      chosenListing: null,
      usableListings: null,
      filteredItems: null,
      filteredItemsCopy: null,
      outputsModals: [],
      selectedBar: null,
      toolBar: null,
      content: null,
      openHouses: null,
      openHousesUpdater: 0,
      bookingUpdater: 0,
    };
  },
  async created() {
    this.setupListings();
    await this.getOpenHouseTasks();
    // this.$emit("listings", this.usableListings);

    window.addEventListener("scroll", this.keepSelectedBarSticky);
    window.addEventListener("resize", this.keepSelectedBarSticky);
  },
  mounted() {
    this.toolBar = document.getElementById("toolbar-main");
    this.content = document.getElementById("listing-content");
    this.collapseAllBoards();
    this.highlightListing();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.keepSelectedBarSticky);
    window.removeEventListener("resize", this.keepSelectedBarSticky);
  },
  watch: {
    async listingTableUpdater() {
      await this.setupListings();
      await this.getOpenHouseTasks();

      this.openedKanbans.forEach((id) => {
        let kanbanRow = document.getElementById("kanbanRow" + id);
        let leadsCountOpen = document.getElementById(id + "leadsnum");
        if (leadsCountOpen && kanbanRow.style.display !== "flex") {
          leadsCountOpen.style =
            "filter: invert(0%) sepia(2%) saturate(7467%) hue-rotate(278deg) brightness(105%) contrast(101%);";
        }
      });
      /*  if (this.openHouses) {
        this.addOpenHousesIntoListings();
        this.openHousesUpdater++;
      } */
    },
    boardLeadsUpdater() {
      this.$emit("boardLeadsUpdated");
    },
    changedObject() {
      this.usableListings.forEach((listing) => {
        if (listing._id === this.changedObject.listingId) {
          listing.status = this.changedObject.status;
          listing.activeUntil = this.changedObject.activeUntil;
        }
      });
    },
    listingsUpdater: async function () {
      //await this.getListings();
      await this.setupListings();
      await this.getOpenHouseTasks();
      this.$emit("listings", this.usableListings);
    },
    statusValues: function () {
      this.usableListings.forEach((listing) => {
        if (listing._id === this.statusValues.listingId) {
          listing.marketplaces = this.statusValues.marketplaces;
        }
      });
    },
    activeTab() {
      this.allListingsSelected = false;
    },
    filtersOpen: function () {
      if (this.selectedBar) {
        this.keepSelectedBarSticky();
      }
    },
    selectedListingsList: {
      async handler() {
        this.selectedListings = this.selectedListingsList;
        if (this.activeTab === "listings") {
         if (
            this.selectedListings.length !==
            this.usableListings.filter(
              (x) =>
                ((x.status !== "archived" || x.status !== "deleted") && !x.isNewDevelopment) && this.user.isAdmin).length
          ) {
            this.allListingsSelected = false;
          }
        } 
        if (this.activeTab === "archives") {
          if (
            this.selectedListings.length !==
            this.usableListings.filter(
              (x) =>
                (x.status === "archived" || x.status === "deleted") && this.user.isAdmin).length
          ) {
            this.allListingsSelected = false;
          }
        }
      },
      deep: true,
    },
    selectedListings: function () {
      this.$parent.$emit("selected", this.selectedListings);
      if (this.selectedListings.length < 1) {
        this.selectedBar = null;
      } else if (this.selectedBar != null) {
        this.selectedBar = document.getElementById("selected-listing-header");
      }
    },
    async listingsLoaded(val) {
      if (val) {
        await this.setupListings();
        this.addOpenHousesIntoListings();
        this.collapseAllBoards();
        this.highlightListing();
        this.$emit("listings", this.usableListings);
      }
    },
    search() {
      let searchValue = this.search.toLowerCase();
      let tempItemsAddress = [...this.originalArr];
      if (this.search != "" && this.search) {
        tempItemsAddress = tempItemsAddress.filter(
          (item) =>
            item.listingName.toLowerCase().indexOf(searchValue) > -1 ||
            item.description.toLowerCase().indexOf(searchValue) > -1
        );
        this.filteredItems = [...tempItemsAddress];
        this.filteredItemsCopy = [...this.filteredItems];
        if (this.filteredItems.length) {
          this.usableListings = this.filteredItems;
          this.sortCounters.forEach((counter) => {
            Object.keys(counter).forEach((key) => {
              if (
                counter[key] !== "number" &&
                counter[key] !== "string" &&
                counter[key] !== 1
              ) {
                counter[key] = counter[key] - 1;
                let isnum = counter.type === "number";

                if (isnum) {
                  this.sortByNumericalValue(key);
                } else {
                  this.sortByStringValue(key);
                }
              }
            });
          });
        } else {
          this.usableListings = [];
        }
      } else if (this.search == "" || this.search == null) {
        this.usableListings = [...this.originalArr];
        this.sortCounters.forEach((counter) => {
          Object.keys(counter).forEach((key) => {
            if (
              counter[key] !== "number" &&
              counter[key] !== "string" &&
              counter[key] !== 1
            ) {
              counter[key] = counter[key] - 1;
              let isnum = counter.type === "number";
              if (isnum) {
                this.sortByNumericalValue(key);
              } else {
                this.sortByStringValue(key);
              }
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "filteredListings",
      "listingsLoaded",
      "user",
      "users",
      "activeTab",
      "listingsNone",
      "listingsUpdater",
      "filters",
    ]),
    listingPrices: function () {
      return this.listings.map((listing) =>
        listing.price ? this.$nMapper.price(listing.price) : "0"
      );
    },
    accessUsers: function () {
      const accessIds = [];
      for (const user of this.users) {
        if (user.adminAccess) {
          accessIds.push(user._id)
        }
      }
      return accessIds;
    }
  },
  methods: {
    ...mapActions([
      "getListings",
      "setClientSidepanel",
      "filterListings",
      "removeFiltersListings",
      "setProvisionalFilter",
    ]),
    getListingOwner(listing) {
      for (const userId in listing.access.users) {
        if (listing.access.users[userId].ownershipType === 'owner') {
          listing.access.users[userId]._id = userId
          return listing.access.users[userId];
        }
      }
      return listing.creator;
    },
    openOutputsModal(position, listing) {
      this.modalPosition = position;
      this.clickedListing = listing;
      this.$nextTick(
        () => (this.toggleOutputsModal = !this.toggleOutputsModal)
      );
    },
    changeLeadsButton() {
      this.openedKanbans.forEach((id) => {
        let leadsCountOpen = document.getElementById(id + "leadsnum");
        if (leadsCountOpen) {
          leadsCountOpen.style.display = "flex";
          leadsCountOpen.style =
            "filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);";
          leadsCountOpen.style.alignItems = "center";
          leadsCountOpen.style.textAlign = "center";
        }
      });
      this.kanbanUpdater++;
    },
    openDropdown(listing) {
      if (listing && !this.chosenListing) {
        this.dropdownIsOpen = true;
        this.chosenListing = listing
      }
    },
    openInfoDropdown(listing) {
      if (listing && !this.chosenListing) {
        if (!this.infoDropdownIsOpen) {
          this.infoDropdownIsOpen = true;
        }
        this.chosenListing = listing
      }
    },
    closeDropdown() {
      this.chosenListing = null
      this.dropdownIsOpen = false;
    },
    closeInfoDropdown() {
      this.chosenListing = null
      this.infoDropdownIsOpen = false
    },
    openBrokerDetailPanel(event, person) {
      let position = {
        left: null,
        top: null,
      };
      position.left = event.pageX + "px";
      position.top = event.clientY + "px";
      this.brokerPanelPosition = position;
      this.clickedBroker = person;
      this.brokerDetailPanelOpened = true;
    },
    closeBrokerDetailPanel() {
      this.brokerDetailPanelOpened = false;
    },
    chooseCoverImage(img) {
      let coverImage = [];
      img.forEach((image) => {
        if (!image.hidden) {
          let name = image.thumbnail || image.name;
          coverImage.push(name);
        }
      });
      return coverImage[0];
    },
    async clearFilters() {
      this.$parent.$emit("clear-filters");
    },
    async changeActiveUntil(changedListing, newDate) {
      if (!this.hasAccess(changedListing)) return;
      newDate = this.formatDate(newDate);
      this.usableListings.forEach((listing) => {
        if (listing._id === changedListing._id) {
          listing.activeUntil = newDate;
        }
      });
      let body = {
        listing: changedListing,
        newDate: newDate,
      };
      await listingApi.updateListingActiveUntil(body);
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date <= currentDate;
    },
    leadPanelClosed() {
      this.leadOpen = false;
      this.boardLeadsUpdater++;
      this.updateKanban();
    },
    customerUpdated() {
      this.updateKanban();
    },
    highlightListing(listingId) {
      this.clearFocusedListing();
      let focusId = this.$route.query.notificationId;
      if (listingId) {
        focusId = listingId;
      }

      const interval = setInterval(() => {
        this.focusedListing = document.getElementById(
          "listing-header-row" + focusId
        );
        if (this.focusedListing) {
          this.focusedListing.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          this.focusedListing.style.border = "2px solid #FF5C01";
          document.documentElement.style.overflow = "visible";
        }
        clearInterval(interval);
      }, 100);
    },
    clearFocusedListing() {
      if (this.focusedListing) {
        this.focusedListing.style.border = "none";
      }
    },
    async openListing(listing) {
      this.clearFocusedListing();
      await this.$router.push({
        name: "ListingsDetail",
        params: {
          listingId: listing,
          fromListingsTable: 1,
          page: this.$route.query.page,
        },
      });
    },
    setNumberOfLeads(leadCount, index) {
      this.usableListings[index].leadsCount = leadCount.leadsCount;
      //this.leadCounterUpdater++;
    },
    async getOpenHouseTasks() {
      const projectIds = this.listings.map((d) => d.linkedDeal);
      const response = await axios.post(
        "/api/tasks/all/project/open-house",
        projectIds
      );
      this.openHouses = response.data;
      this.addOpenHousesIntoListings();
      this.leadCounterUpdater++;
    },
    addOpenHousesIntoListings() {
      this.openHouses.forEach((o) => {
        this.originalArr.forEach((l) => {
          if (l.linkedDeal === o.linkedProject.id) {
            l.openHouse = o;
            return false;
          }
        });
      });
    },
    async setupListings() {
      this.originalArr = [...this.listings];
      this.usableListings = this.listings;
      const listingIds = this.listings.map((listing) => listing.creator.email);
      const resp = await axios.post("/api/admin/all/group-name", listingIds);

      this.listings.forEach((listing) => {
        resp.data.forEach((admin) => {
          if (listing.creator.email === admin.email) {
            let defaultGroup = admin.groups.find((group) => group.userDefault)
            if (!defaultGroup) {
              listing.creator.groups = admin.groups
                  .map((group) => group.name)
                  .join(", ");
            } else {
              listing.creator.groups = defaultGroup.name;
            }

          }
        });
        this.outputsModals.push({ status: false });
      });

      this.usableListings.forEach(() => {
        this.outputsModals.push({ status: false });
      });
      this.usableListings.forEach((listing) => {
        if (listing.sharedCreators) {
          listing.sharedCreators.forEach((creator) => {
            if (creator.userId === this.user._id) {
              listing.userInSharedCreators = true;
            }
          });
        }
      });
      await this.getListingsSuggestionsCounts();
      await this.updateKanban();
      this.highlightListing();
      if (this.openedKanbans && this.openedKanbans.length > 0) {
        this.changeLeadsButton();
      }
    },
    async updateKanban() {
      this.kanbanUpdater++;
    },
    async getListingsSuggestionsCounts() {
      const listingIds = this.usableListings.map((listing) => {
        return listing._id;
      });
      const resp = await axios.post(
        "/api/listing/auto-suggest/count/all",
        listingIds
      );
      const counts = resp.data;
      for (const listingId in counts) {
        this.usableListings.forEach((listing, index) => {
          if (listing._id === listingId) {
            this.usableListings[index].suggestionsCount = counts[listingId];
            this.leadCounterUpdater++;
          }
        });
      }
    },
    formatDate(item) {
     if (item) {
        return moment(item).format("DD.MM.YYYY");
      } else {
        return "-";
      }
    },
    changeListingStatus(event) {
      if (event.status === "booked") {
        this.bookingUpdater++;
      }
      this.statusesOpen = false;
      this.usableListings.forEach((listing, index) => {
        if (listing._id === event.listingId) {
          listing.status = event.status;
          listing.bookedUntil = event.bookedUntil;
          listing.bookerContact = event.bookerContact
            ? event.bookerContact
            : null;
          listing.bookingType = event.bookingType ? event.bookingType : null;
          listing.bookingComment = event.bookingComment
            ? event.bookingComment
            : null;
          if (event.activeUntil) {
            listing.activeUntil = event.activeUntil;
          }
          if (event.status === "archived") {
            this.usableListings.splice(index, 1);
          }
        }
      });
      this.listingTableUpdater++;
    },
    changeListingsStatuses(event) {
      this.statusesOpen = false;
      event.statusObjects.forEach((statusObject) => {
        this.usableListings.forEach((listing, index) => {
          if (listing._id === statusObject.listingId) {
            listing.status = statusObject.status;
            listing.bookedUntil = statusObject.bookedUntil;
            listing.activeUntil = event.activeUntil;
            if (this.activeTab === "archives") {
              this.usableListings.splice(index, 1);
            }
          }
        });
      });
    },
    openStatusDropdown(listing, index) {
      if (!this.hasAccess(listing)) return;
      let button = document.getElementById("statusLabel" + index);
      const rect = button.getBoundingClientRect();
      this.position.right = window.innerWidth - rect.right;
      this.position.top = rect.top + window.scrollY - 50;
      this.selectedListing = listing;
      this.statusesOpen = true;
    },
    openKanbanLead(lead) {
      if (lead.module && lead.module === "projects") {
        this.$router.push({
          name: "DealDetail",
          params: { id: lead.leadId },
        });
      } else {
        this.leadOpen = true;
        this.clickedLead = lead;
      }
    },
    openBookingDetailsModal(listing) {
      if (!this.hasAccess(listing)) return;
      this.bookedListing = listing;
      this.bookingDetailsModalOpen = true;
    },
    closeBookingDetailsModal() {
      this.bookingDetailsModalOpen = false;
    },
    keepSelectedBarSticky() {
      this.toolBar = document.getElementById("toolbar-main");
      this.selectedBar = document.getElementById("selected-listing-header");
      this.content = document.getElementById("listing-content");
      if (this.toolBar && this.toolBar.classList.contains("sticky")) {
        if (this.selectedBar) {
          this.content.style.paddingTop =
            this.toolBar.offsetHeight + this.selectedBar.offsetHeight + "px";
          this.selectedBar.classList.add("sticky");
          this.selectedBar.style.marginTop = this.toolBar.offsetHeight + "px";
        } else {
          this.content.style.paddingTop = this.toolBar.offsetHeight + "px";
        }
      } else {
        this.content.style.paddingTop = "0px";
        if (this.selectedBar) {
          this.selectedBar.classList.remove("sticky");
          this.selectedBar.style.marginTop = "0px";
        }
      }
    },
    sortByStringValue(sortingField) {
      let sortingStep = null;
      let sortingCounterIndex = null;
      this.sortCounters.forEach((counter, index) => {
        Object.keys(counter).forEach((key) => {
          if (key === sortingField) {
            sortingCounterIndex = index;
            sortingStep = counter[key];
          } else if (key !== sortingField && key !== "type") {
            counter[key] = 1;
          }
        });
      });

      if (sortingStep == 3 && (this.search == null || this.search == "")) {
        this.sortCounters[sortingCounterIndex][sortingField] = 1;
        let data = {
          sortField: sortingField,
          sortOrder: sortingStep,
        };

        this.$emit("sortListings", data);
      } else if (
        sortingStep == 3 &&
        (this.search != null || this.search != "")
      ) {
        this.sortCounters[sortingCounterIndex][sortingField] = 1;
        let data = {
          sortField: sortingField,
          sortOrder: sortingStep,
        };

        this.$emit("sortListings", data);
      } else if (sortingStep == 1) {
        if (sortingField == "estateType") {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          let data = {
            sortField: sortingField,
            sortOrder: sortingStep,
          };

          this.$emit("sortListings", data);
        } else if (sortingField == "broker") {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          let data = {
            sortField: sortingField,
            sortOrder: sortingStep,
          };

          this.$emit("sortListings", data);
        } else {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          let data = {
            sortField: sortingField,
            sortOrder: sortingStep,
          };

          this.$emit("sortListings", data);
        }
      } else if (sortingStep == 2) {
        if (sortingField == "estateType") {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          let data = {
            sortField: sortingField,
            sortOrder: sortingStep,
          };

          this.$emit("sortListings", data);
        } else if (sortingField == "broker") {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          let data = {
            sortField: sortingField,
            sortOrder: sortingStep,
          };

          this.$emit("sortListings", data);
        } else {
          this.sortCounters[sortingCounterIndex][sortingField] += 1;
          let data = {
            sortField: sortingField,
            sortOrder: sortingStep,
          };

          this.$emit("sortListings", data);
        }
      }
    },
    sortByNumericalValue(sortingField) {
      let sortingStep = null;
      let sortingCounterIndex = null;
      this.sortCounters.forEach((counter, index) => {
        Object.keys(counter).forEach((key) => {
          if (key === sortingField) {
            sortingCounterIndex = index;
            sortingStep = counter[key];
          } else if (key !== sortingField && key !== "type") {
            counter[key] = 1;
          }
        });
      });

      if (sortingStep == 3 && (this.search == null || this.search == "")) {
        this.sortCounters[sortingCounterIndex][sortingField] = 1;

        let data = {
          sortField: sortingField,
          sortOrder: sortingStep,
        };

        this.$emit("sortListings", data);
      } else if (
        sortingStep == 3 &&
        (this.search != null || this.search != "")
      ) {
        this.sortCounters[sortingCounterIndex][sortingField] = 1;
        let data = {
          sortField: sortingField,
          sortOrder: sortingStep,
        };

        this.$emit("sortListings", data);
      } else if (sortingStep == 1) {
        this.sortCounters[sortingCounterIndex][sortingField] += 1;
        let data = {
          sortField: sortingField,
          sortOrder: sortingStep,
        };
        this.$emit("sortListings", data);
      } else if (sortingStep == 2) {
        this.sortCounters[sortingCounterIndex][sortingField] += 1;
        let data = {
          sortField: sortingField,
          sortOrder: sortingStep,
        };

        this.$emit("sortListings", data);
      }
    },
    sortLandArea(sortingField, a, b, reverse) {
      if (sortingField !== "generalSurfaceArea") return null;

      let aArea = a[sortingField];
      let bArea = b[sortingField];

      if (!aArea && a["estateType"] === "land" && a["landArea"]) {
        if (a["landArea"]["m2"]) {
          aArea = parseInt(a["landArea"]["m2"]);
        } else if (a["landArea"]["ha"]) {
          aArea = parseInt(a["landArea"]["ha"]) * 1000;
        }
      }

      if (!bArea && b["estateType"] === "land" && b["landArea"]) {
        if (b["landArea"]["m2"]) {
          bArea = parseInt(b["landArea"]["m2"]);
        } else if (b["landArea"]["ha"]) {
          bArea = parseInt(b["landArea"]["ha"]) * 1000;
        }
      }

      if (reverse) {
        if (!bArea) return -1;
        return +bArea - +aArea;
      } else {
        if (!aArea) return -1;
        if (!bArea) return 1;
        return +aArea - +bArea;
      }
    },
    formatListingType(e) {
      if (e == "sale") {
        return this.$t("Sale");
      } else if (e == "rent") {
        return this.$t("Rent");
      } else if (e == "short_term_rent") {
        return this.$t("ShortTermRent");
      } else {
        return "-";
      }
    },
    async updateListings() {
      await this.setupListings();
      this.refreshBoardLeads();
    },
    refreshBoardLeads() {
      this.boardLeadsUpdater++;
      this.$emit("updateOffersCounter");
    },
    openLead(lead) {
      //this.leadPanelOpen = true;
      this.clickedLead = lead;
    },
    numberOfLeads(listing) {
      if (listing.leadsCount) {
        if (listing.leadsCount === 1) {
          return listing.leadsCount + ` ${this.$t("Lead")}`;
        } else {
          return listing.leadsCount + ` ${this.$t("NumerableLeads")}`;
        }
      }

      let leadsLength = 0;
      // eslint-disable-next-line no-unused-vars
      for (const [key, value, index] of Object.entries(listing.funnels)) {
        if (key === "Matches") {
          continue;
        }

        value.forEach(() => {
          leadsLength++;
        });
      }
      if (listing.suggestionsCount) {
        leadsLength = leadsLength + listing.suggestionsCount;
      }

      if (leadsLength === 1) {
        return leadsLength + ` ${this.$t("Lead")}`;
      } else {
        return leadsLength + ` ${this.$t("NumerableLeads")}`;
      }
    },
    handleListingSelection(listing) {
      if (
        !this.selectedListings.some((selList) => selList._id === listing._id)
      ) {
        this.selectedListings.push(listing);
        if (
          this.activeTab === "listings" &&
          this.usableListings.filter(
            (x) =>
              (x.status !== "archived" || x.status !== "deleted") &&
              /* x.creator.userId === this.user._id */ this.user.isAdmin
          ).length === this.selectedListings.length
        ) {
          this.allListingsSelected = true;
        }
        if (
          this.activeTab === "archives" &&
          this.usableListings.filter(
            (x) =>
              (x.status === "archived" || x.status === "deleted") &&
              /* x.creator.userId === this.user._id */ this.user.isAdmin
          ).length === this.selectedListings.length
        ) {
          this.allListingsSelected = true;
        }
      } else {
        this.selectedListings = this.selectedListings.filter(
          (x) => x._id !== listing._id
        );
        if (this.allListingsSelected) {
          this.allListingsSelected = false;
        }
      }
    },
    handleAllListingSelections() {
      const usableListingIds = this.usableListings
        .map((listing) => listing._id)
        .filter((id) =>
          this.selectedListings.forEach(
            (element) => id !== element._id && !element.isNewDevelopment
          )
        );
      if (!this.allListingsSelected) {
        this.selectedListings = [];
        this.usableListings.forEach((element) => {
          if (
            !this.selectedListings.some((element) =>
              usableListingIds.forEach((id) => element._id === id)) && this.hasAccess(element) && !element.isNewDevelopment) {
            this.selectedListings.push(element);
          }
        });
        this.allListingsSelected = true;
      } else {
        this.selectedListings = [];
        this.allListingsSelected = false;
      }
    },
    collapseAllBoards() {
      this.usableListings.forEach((listing) => {
        this.collapseBoard(listing._id, true);
      });
      this.kanbanUpdater++;
    },
    collapseBoard(index, bulk) {
      let kanbanRowId = "kanbanRow" + index; //next table row index from clicked listing
      //let rowHeight = document.getElementById("collapsePart");
      let separatorLine = document.getElementById("separator" + index);
      let arrowUp = document.getElementById(index + "up");
      let arrowDown = document.getElementById(index + "down");
      let buttonContainer = document.getElementById(index + "button-container");
      let leadsCount = document.getElementById(index + "leadsnum");
      let clickedKanbanRow = document.getElementById(kanbanRowId);
      if (clickedKanbanRow.style.display !== "flex" && !bulk) {
        clickedKanbanRow.style.display = "flex";
        separatorLine.style.display = "flex";
        clickedKanbanRow.classList.remove("kanban-close");
        clickedKanbanRow.classList.add("kanban-open");
        buttonContainer.style.backgroundColor = "#000000";
        buttonContainer.style.borderColor = "#000000";
        leadsCount.classList.add("leads-open");
        leadsCount.style =
          "filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);";
        leadsCount.style.display = "flex";
        leadsCount.style.alignItems = "center";
        leadsCount.style.textAlign = "center";
        arrowUp.style =
          "filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);";
        arrowUp.style.display = "block";
        arrowUp.style.width = "13px";
        arrowUp.style.width = "13px";
        arrowDown.style.display = "none";
        this.openedKanbans.push(index);
        //console.log("avan", this.usableListings[index]);
        //this.updateKanban();
      } else {
        //this.usableListings[index].kanbanOpen = false;
        clickedKanbanRow.classList.remove("kanban-open");
        clickedKanbanRow.classList.add("kanban-close");
        setTimeout(() => {
          clickedKanbanRow.style.display = "initial";
          separatorLine.style.display = "none";
        }, 900);
        arrowUp.style.display = "none";
        buttonContainer.style.backgroundColor = "#ffffff";
        buttonContainer.style.borderColor = "#E6E8EC";
        buttonContainer.style.border = "1px solid #E6E8EC !important";
        arrowDown.style =
          "filter: invert(0%) sepia(2%) saturate(7467%) hue-rotate(278deg) brightness(105%) contrast(101%);";
        leadsCount.style =
          "filter: invert(0%) sepia(2%) saturate(7467%) hue-rotate(278deg) brightness(105%) contrast(101%);";
        leadsCount.style.display = "flex";
        leadsCount.style.alignItems = "center";
        leadsCount.style.textAlign = "center";
        arrowDown.style.display = "block";
        arrowDown.style.width = "13px";
        arrowDown.style.width = "13px";
        this.openedKanbans = this.openedKanbans.filter((e) => e !== index);
        //this.usableListings[index].kanbanOpen = false;

        //this.updateKanban();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../global_variables";

.col {
  padding: 0;
}

.content {
  display: block;
  padding-left: 104px;
  padding-right: 88px;
  min-height: 600px;
  background-color: #f4f5f7;
}
.no-results-listing {
  margin-top: 48px;
}
.clear-filters-button {
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;

  color: #75787a;

  margin: 13px 0px;
}
.customer-row {
  margin-bottom: 0px !important;
  background-color: #f4f5f7 !important;
  min-width: 1290px;
  //width: fit-content;
  max-width: 1290px;
  padding-top: 24px;
}

.tag {
  padding: 4px 8px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
}

.tag:before {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #0aaf60;
}

.title {
  width: 100%;
}

img,
p {
  display: inline-block;
}

img {
  float: left;
}

.gray {
  background: #f4f5f7;
}

.green {
  background: #0aaf60;
  color: white;
}

.tag {
  height: 24px;
  padding: 4px 8px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: #f4f5f7;
}

.listing-title {
  height: 40px;
  background-color: white;
  //margin-top: -4px;
  border-color: #e6e8ec !important;
  border-bottom: 1px solid #e6e8ec !important;
  border-radius: 8px 8px 0px 0px;

  .checkbox-wrapper {
    display: flex;
    min-width: 32px;
    max-width: 32px;
    margin-top: 4px;
    margin-left: 8px;
    justify-content: center;
  }

  .address-wrapper {
    display: flex;
    align-content: flex-start;
    min-width: 288px;
    max-width: 288px;
    cursor: pointer;
  }

  .city-wrapper {
    display: flex;
    align-content: flex-start;
    cursor: pointer;
    min-width: 96px;
    max-width: 96px;
  }

  .listing-type-wrapper {
    display: flex;
    align-content: flex-start;
    cursor: pointer;
    min-width: 112px;
    max-width: 112px;
  }

  .estate-type-wrapper {
    display: flex;
    align-content: flex-start;
    min-width: 130px;
    max-width: 130px;
  }

  .rooms-wrapper {
    display: flex;
    align-content: flex-start;
    width: 70px;
    max-width: 70px;
  }

  .size-wrapper {
    display: flex;
    align-content: flex-start;
    min-width: 80px;
    max-width: 100px;
  }

  .price-wrapper {
    display: flex;
    align-content: flex-start;
    min-width: 80px;
    max-width: 80px;
  }

  .broker-wrapper {
    display: flex;
    align-content: flex-start;
    min-width: 132px;
    max-width: 132px;
  }

  .status-wrapper {
    display: flex;
    align-content: flex-start;
    min-width: 80px;
    max-width: 80px;
  }
}

.listing-details {
  min-width: 1000px;
  min-height: 88px;
  background-color: white;
  &:hover {
    background: $light-grey;
  }

  .checkbox-column {
    padding-left: 8px;
    min-width: 40px;
    max-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-column {
    display: flex;
    align-items: center;
    flex-grow: 0;
    padding-left: 8px;
    min-width: 88px;
    max-height: 88px;
  }

  .no-image {
    width: 78px;
    height: 52px;
    border-radius: 8px;
    background-color: #f4f5f7;
  }

  .address-column {
    padding-left: 8px;
    flex-grow: 0;
    min-width: 200px;
    max-width: 200px;
  }

  .address-name {
    height: 20px;
    padding-top: 32px;
    padding-bottom: 0;
  }

  .address-flex {
    align-self: center;
    gap: 4px;
    display: grid;
  }

  .city-column {
    flex-grow: 0;
    width: 96px;
    padding-left: 8px;
  }

  .city-wrapper {
    height: 20px;
    padding-top: 32px;
    width: 96px;
    display: flex;
    align-items: center;
  }

  .listing-type-column {
    flex-grow: 0;
    width: 112px;
    padding-left: 8px;
  }

  .listing-type-wrapper {
    height: 20px;
    padding-top: 32px;
    width: 112px;
    display: flex;
    align-items: center;
  }

  .estate-type-column {
    flex-grow: 0;
    width: 130px;
    padding-left: 8px;
  }

  .estate-type-wrapper {
    height: 20px;
    padding-top: 32px;
    width: 130px;
    display: flex;
    align-items: center;
  }

  .rooms-column {
    flex-grow: 0;
    width: 70px;
    padding-left: 8px;
  }

  .rooms-wrapper {
    height: 20px;
    padding-top: 32px;
    width: 80px;
    display: flex;
    align-items: center;
  }

  .size-column {
    flex-grow: 0;
    width: 100px;
    padding-left: 8px;
  }

  .size-wrapper {
    height: 20px;
    padding-top: 32px;
    width: 100px;
    display: flex;
    align-items: center;
  }

  .price-column {
    flex-grow: 0;
    width: 90px;
    padding-left: 8px;
  }

  .broker-column {
    width: 140px;
    padding-left: 8px;
    align-self: center;
    .hover-option {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .price-wrapper {
    height: 20px;
    padding-top: 32px;
    width: 90px;
    display: flex;
    align-items: center;
  }

  .broker-wrapper {
    height: 20px;
    padding-top: 24px;
    width: 140px;
    display: flex;
    align-items: center;
  }

  .kanban-button-column {
    display: flex;
    min-width: 120px;
    max-width: 120px;
    padding-left: 15px;
    padding-right: 8px;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .leads-number-text {
    padding-left: 8px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.kanban-separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
  display: none;
}

.listing-details:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.title-items {
  padding-left: 8px;
  display: flex;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.orange {
  background: #ff5c01;
  color: white;
}

.listing-item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  overflow: hidden;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  max-width: fit-content;
  border-radius: 4px;
  cursor: pointer;
}

.statusLabel.booked-label {
  border-radius: 4px 0 0 4px;
}

.booked-info-label {
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  gap: 10px;
  min-height: unset;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: black;
    cursor: pointer;
  }
}

.booking-until-date {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.leads-open {
  padding-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.free {
  background-color: #0aaf60;
}

.white-background {
  background-color: #ffffff;
}

.listing-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #75787a;
}

#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}

.slide-animation {
  margin-left: 256px !important;
}

.slide-animation-close {
  margin-left: 0 !important;
}

.object-row {
  background: #ffff;
  border-radius: 8px;
  padding: 18px;
  width: 100%;
  margin-top: 16px;

  .img-col {
    cursor: pointer;
    overflow: hidden;

    img {
      border-radius: 8px;
    }
  }

  .info-col {
    padding-left: 16px;

    .listing-title2 {
      font-family: "Geomanist", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .info-icon {
      width: 24px;
      height: 24px;
    }

    .info-text {
      padding-left: 8px;
    }
  }

  img {
    height: auto;
    width: 100%;
  }
}

.relation:hover {
  cursor: pointer;
  color: dodgerblue;
}

.tags-row {
  margin-top: 16px;

  .booking {
    margin-bottom: 0 !important;
    padding-top: 4px;
  }
}

.tags {
  p {
    margin-bottom: 0 !important;
    font-size: 12px;
    line-height: 16px;
  }

  .active {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-image: url("../../assets/images/green-circle.svg");
  }

  .tag-wrapper {
    align-items: center;
  }
}

.kanbanRow {
  margin-top: 0px;
  display: none;
}
.price-history-log {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.kanban-close {
  -webkit-animation: closetransition 1s forwards; /* Safari 4+ */
  -moz-animation: closetransition 1s forwards; /* Fx 5+ */
  -o-animation: closetransition 1s forwards; /* Opera 12+ */
  animation: closetransition 1s forwards; /* IE 10+, Fx 29+ */
  height: 414px;
  opacity: 1;
}

@keyframes closetransition {
  20% {
    opacity: 0.4;
    height: 414px;
  }
  50% {
    opacity: 0.2;
    overflow: hidden;
  }
  100% {
    height: 0px;
    opacity: 0;
    overflow: hidden;
  }
}

.kanban-open {
  -webkit-animation: opentransition 1s forwards; /* Safari 4+ */
  -moz-animation: opentransition 1s forwards; /* Fx 5+ */
  -o-animation: opentransition 1s forwards; /* Opera 12+ */
  animation: opentransition 1s forwards; /* IE 10+, Fx 29+ */
  height: 0px;
  opacity: 0;
}

@keyframes opentransition {
  20% {
    height: 0px;
    overflow: hidden;
  }
  50% {
    opacity: 0.2;
    overflow: hidden;
  }
  80% {
    opacity: 0.4;
  }
  100% {
    height: 414px;
    opacity: 1;
  }
}

.price-info-container, .info-container {
  cursor: pointer;
  position: relative;
  display: flex;
  border-radius: 8px;
}
.price-info-dropdown {
  display: flex;
  align-items: center;
}

.price-info-container-hover:hover, .info-container-hover:hover {
  background-color: black !important;
}

.price-info-container-hover:hover img{
  filter: invert(100%) sepia(0) saturate(0) hue-rotate(317deg) brightness(103%)
    contrast(102%);
}
.price-history-info-dropdown, .info-dropdown {
  box-shadow: rgba(0, 0, 0, 0.11) 0px 2px 8px, rgba(0, 0, 0, 0.11) 2px 1px 9px, rgba(0, 0, 0, 0.1) 0px 0px 0px;
  position: absolute;
  z-index: 8;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  gap: 12px;
  padding: 16px;
}

.price-info {
  border-radius: 8px 8px 0 0;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.in-active {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-image: url("../../assets/images/red-circle.svg");
}

.tag-wrapper {
  align-items: center;
}

.button-col {
  display: flex;
  justify-content: flex-end;

  .v-btn {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    min-width: 10px !important;
  }
}

.up {
  display: initial;
}

.down {
  display: initial;
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.outputs-modal {
  background: white;
  padding: 16px;
}

.outputs {
  width: 18px;
  height: 18px;
}

.output-row {
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
}

.output-checkbox {
  width: 100px;
}

.output-account {
  width: 200px;
}

.button-leads {
  margin-left: -8px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  min-width: 89px;
  height: 32px;
  white-space: nowrap;
  border-style: solid;
  border: 1px solid #e6e8ec !important;
}

.button-leads-big {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  min-width: 97px;
  max-width: 97px;
  height: 32px;
  border-style: solid;
  border: 1px solid #e6e8ec !important;
}

.normal-weight {
  font-weight: 400;
}

.bold-weight {
  font-weight: 700;
}

.listing-status-button-active {
  height: 20px;
  min-width: 320px;
  max-width: 320px;
  display: flex;
}

.listing-status-button-passive {
  display: flex;
  align-self: center;
  flex-grow: 0;
  min-width: 320px;
  max-width: 320px;
}
</style>
