var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('ListContent', {
    attrs: {
      "listing-table-updater": _vm.listingTableUpdater,
      "listings": _vm.listings,
      "selected-listings-list": _vm.selectedlistings,
      "active-filters": _vm.activeFilters,
      "conditionsObj": _vm.conditionsObj,
      "total-count": _vm.totalListings,
      "statisticsWidgetStatus": _vm.statisticsWidgetStatus,
      "selectedBrokerId": _vm.selectedBrokerId
    },
    on: {
      "sortListings": _vm.sortListings,
      "boardLeadsUpdated": function ($event) {
        return _vm.$emit('boardLeadsUpdated');
      },
      "widget-status": _vm.widgetStatus
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }