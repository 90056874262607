var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    staticClass: "widget-overlay",
    staticStyle: {
      "position": "fixed"
    },
    style: _vm.extended && !_vm.inTopArea ? 'top: calc(100vh - 399px)' : null,
    attrs: {
      "z": 3,
      "resizable": false,
      "x": _vm.posX,
      "y": _vm.posY,
      "drag-handle": ".drag-area",
      "id": "widget-overlay"
    },
    on: {
      "dragstop": _vm.onDragStop
    }
  }, [_c('div', {
    attrs: {
      "id": "widget"
    }
  }, [_c('div', {
    staticClass: "drag-area"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/menu.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "statistics-header",
    on: {
      "click": _vm.revertExtension
    }
  }, [_c('img', {
    staticClass: "statistics-chevron",
    attrs: {
      "src": _vm.extended ? require('../../assets/images/collapse-down.svg') : require('../../assets/images/collapse-up.svg'),
      "alt": ""
    }
  }), !_vm.extended ? _c('h5', {
    staticClass: "statistics-header-text"
  }, [_vm._v(" " + _vm._s(_vm.totalListingsCount) + " " + _vm._s(_vm.$t("Listingst")) + " ")]) : _vm._e(), _vm.extended && !_vm.mainInfoLoaded ? _c('h5', {
    staticClass: "statistics-header-text"
  }, [_vm._v(" " + _vm._s(_vm.totalListingsCount) + " " + _vm._s(_vm.$t("Listingst")) + " ")]) : _vm._e(), _vm.extended && _vm.mainInfoLoaded ? _c('h5', {
    staticClass: "statistics-header-text"
  }, [_vm._v(" " + _vm._s(_vm.statistics.listingsTotal.total) + " " + _vm._s(_vm.$t("Listingst")) + " ")]) : _vm._e()]), _vm.extended ? _c('div', {
    staticClass: "statistics-content",
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "min-width": "290px"
    }
  }, [!_vm.mainInfoLoaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "width": "50px",
      "height": "50px"
    }
  }) : _vm._e(), _vm.mainInfoLoaded ? _c('div', [_c('div', {
    staticClass: "statistics-sites-info"
  }, [_c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v("KV")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.kv.quantity) + " / " + _vm._s(_vm.statistics.kv.total) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v("CITY24")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.city24.quantity) + " / " + _vm._s(_vm.statistics.city24.total) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v("KV24")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.kv24.quantity) + " / " + _vm._s(_vm.statistics.kv24.total) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v("WWW")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.www.quantity) + " / " + _vm._s(_vm.statistics.www.total) + " ")])])]), _c('div', {
    staticClass: "line"
  }), _c('div', {
    staticClass: "statistics-estate-info"
  }, [_c('span', {
    staticClass: "statistics-estate-con"
  }, [_c('h6', {
    staticClass: "statistics-estate-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Apartment")) + " ")]), _c('div', {
    staticClass: "statistics-estate-numbers"
  }, [_c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sale")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.apartment.sale) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Rent")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.apartment.rent) + " ")])])])]), _c('span', {
    staticClass: "statistics-estate-con"
  }, [_c('h6', {
    staticClass: "statistics-estate-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("House")) + " ")]), _c('div', {
    staticClass: "statistics-estate-numbers"
  }, [_c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sale")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.house.sale) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Rent")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.house.rent) + " ")])])])]), _c('span', {
    staticClass: "statistics-estate-con"
  }, [_c('h6', {
    staticClass: "statistics-estate-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Land")) + " ")]), _c('div', {
    staticClass: "statistics-estate-numbers"
  }, [_c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sale")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.land.sale) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Rent")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.land.rent) + " ")])])])]), _c('span', {
    staticClass: "statistics-estate-con"
  }, [_c('h6', {
    staticClass: "statistics-estate-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Commercial")) + " ")]), _c('div', {
    staticClass: "statistics-estate-numbers"
  }, [_c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sale")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.commercial.sale) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Rent")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.commercial.rent) + " ")])])])]), _c('span', {
    staticClass: "statistics-estate-con"
  }, [_c('h6', {
    staticClass: "statistics-estate-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("HousePart")) + " ")]), _c('div', {
    staticClass: "statistics-estate-numbers"
  }, [_c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sale")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.house_part.sale) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Rent")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.house_part.rent) + " ")])])])]), _c('span', {
    staticClass: "statistics-estate-con"
  }, [_c('h6', {
    staticClass: "statistics-estate-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Cottage")) + " ")]), _c('div', {
    staticClass: "statistics-estate-numbers"
  }, [_c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sale")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.cottage.sale) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Rent")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.cottage.rent) + " ")])])])]), _c('span', {
    staticClass: "statistics-estate-con"
  }, [_c('h6', {
    staticClass: "statistics-estate-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Garage")) + " ")]), _c('div', {
    staticClass: "statistics-estate-numbers"
  }, [_c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sale")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.garage.sale) + " ")])]), _c('div', [_c('div', {
    staticClass: "statistics-site-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Rent")) + " ")]), _c('div', {
    staticClass: "statistics-site-numbers"
  }, [_vm._v(" " + _vm._s(_vm.statistics.garage.rent) + " ")])])])])])]) : _vm._e()]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }