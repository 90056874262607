var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delete-overlay"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [_c('v-row', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "10px",
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("../../assets/images/info.svg"),
      "alt": ""
    }
  }), _vm.count !== 1 && this.$i18n.locale == 'en' ? _c('p', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.$t("PleaseConfirmDuplication") + " " + _vm.count + " " + _vm.$t("Items").toLowerCase()) + " ")]) : _vm._e(), _vm.count !== 1 && this.$i18n.locale == 'et' ? _c('p', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.$t("PleaseConfirm") + " " + _vm.count + " " + _vm.$t("Items").toLowerCase() + " " + _vm.$t("Duplication").toLowerCase()) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel content",
    staticStyle: {
      "line-height": "16px"
    },
    on: {
      "click": _vm.canceled
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "delete content",
    staticStyle: {
      "background-color": "black",
      "color": "white",
      "line-height": "16px"
    },
    on: {
      "click": _vm.approved
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(_vm._s(_vm.$t("Confirm")))]) : _vm._e()])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }