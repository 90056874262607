<template>
  <div class="dropdown">
    <button @click="showDropdown" class="add-new-button dropbtn" style="display: none">
      <img src="../../assets/images/action-add.svg" alt="" />{{ $t("AddNew") }}
    </button>
    <div id="myDropdown" class="dropdown-content">
      <p
        v-if="!user.config || (user.config && user.access?.features?.modules?.projects?.enabled)"
        @click="
          changeSidepanelStateDeals();
          showDropdown();
        "
      >
        {{ $t("Project") }}
      </p>
      <p v-if="!user.config || (user.config && user.access?.features?.modules?.calendar?.enabled)" style="color: gray !important">{{ $t("Task") }}</p>
      <p @click="$router.push({ name: 'ListingsAdd' })">{{ $t("Listing") }}</p>
      <p
        @click="
          changeClientAddModal();
          showDropdown();
        "
      >
        {{ $t("Contact") }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddNewButton",
  components: {},
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  created() {},
  mounted() {
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  },
  methods: {
    ...mapActions(["changeSidepanelStateDeals", "changeClientAddModal"]),
    showDropdown() {
      document.getElementById("myDropdown").classList.toggle("show");
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-content {
  display: none;
  margin-top: 4px;
  position: absolute;
  background-color: white;
  width: 160px;
  max-width: 160px;
  overflow: auto;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  p {
    padding: 12px 12px 12px 24px;
    font-size: 14px;
    line-height: 16px;
    &:hover {
      background-color: #f4f5f7;
      cursor: pointer;
    }
  }
}
.show {
  display: block;
}
</style>
