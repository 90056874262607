var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.clientSidepanelStatus ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.offersCustomerId,
      "only-panel": true
    },
    on: {
      "funnelRemoved": function ($event) {
        return _vm.getListings();
      }
    }
  }) : _vm._e(), _vm.listingSidepanelStatus ? _c('ListingSidepanel', {
    key: _vm.contentUpdater,
    attrs: {
      "listing-id": _vm.offersListingId,
      "dont-nav-to-previous-route": _vm.fromListingsAdd,
      "previous-listing-page": _vm.lastVisitedListingPage,
      "selected-view": _vm.activeTab
    },
    on: {
      "statusChanged": _vm.updateListing,
      "updateStatuses": _vm.updateFromSidepanel
    }
  }) : _vm._e(), _c('Header', {
    ref: "header",
    attrs: {
      "id": "listing-header",
      "filterConditions": _vm.filterConditions,
      "saved-view-id": _vm.savedViewId,
      "board-leads-updater": _vm.boardLeadsUpdater,
      "selected-view": _vm.activeTab
    },
    on: {
      "selectedBroker": _vm.selectBrokerId,
      "activeTab": _vm.changeTab,
      "filtersOpen": _vm.filtersOpen
    }
  }), _vm.selectedListingsList.length > 0 ? _c('div', {
    staticClass: "selected-listings-header",
    class: {
      'slide-animation-open': _vm.contactBarOpen
    },
    attrs: {
      "id": "selected-listing-header"
    }
  }, [_c('div', {
    staticClass: "selected-listings-display"
  }, [_c('p', {
    staticStyle: {
      "font-size": "10px",
      "line-height": "16px",
      "font-weight": "600",
      "font-family": "Inter, serif"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedListingsList.length + " " + _vm.$t("Selected")) + " ")]), _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": require("../../assets/images/close_red.svg")
    },
    on: {
      "click": _vm.clearListings
    }
  })]), _c('div', {
    staticClass: "divider"
  }, [_vm._v("•")]), _c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "margin-right": "8px"
    },
    attrs: {
      "id": "change-status-bulk"
    },
    on: {
      "click": _vm.openStatusDropdown
    }
  }, [_c('p', {
    staticClass: "filter",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeStatus")) + " ")]), _c('img', {
    staticClass: "filter",
    attrs: {
      "src": require("../../assets/images/chevron-down.svg"),
      "alt": ""
    }
  })]), _c('GroupOrUserSelect', {
    attrs: {
      "emptyValueLabelTranslation": "ChangeUser",
      "color": "invert",
      "allow-empty": false,
      "allow-only-first-level-data": true
    },
    model: {
      value: _vm.chosenUsers,
      callback: function ($$v) {
        _vm.chosenUsers = $$v;
      },
      expression: "chosenUsers"
    }
  }), _c('div', {
    staticClass: "divider"
  }, [_vm._v("•")]), _vm.selectedListingsList.length === 1 ? _c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "margin-right": "8px"
    },
    on: {
      "click": _vm.duplicateListings
    }
  }, [_c('p', {
    staticClass: "filter"
  }, [_vm._v(_vm._s(_vm.$t("Duplicate")))])]) : _vm._e(), _vm.selectedListingsList.length > 1 ? _c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "margin-right": "8px"
    },
    attrs: {
      "id": "duplicate-button"
    },
    on: {
      "click": _vm.duplicateModalOpen
    }
  }, [_c('p', {
    staticClass: "filter"
  }, [_vm._v(_vm._s(_vm.$t("Duplicate")))])]) : _vm._e(), _vm.activeTab === 'listings' ? _c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "margin-right": "8px"
    },
    attrs: {
      "id": "delete-button"
    },
    on: {
      "click": _vm.deleteModal
    }
  }, [_c('p', {
    staticClass: "filter"
  }, [_vm._v(_vm._s(_vm.$t("Delete")))])]) : _vm._e(), _vm.activeTab === 'archives' ? _c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "margin-right": "8px"
    },
    on: {
      "click": _vm.restoreListings
    }
  }, [_c('p', {
    staticClass: "filter"
  }, [_vm._v(_vm._s(_vm.$t("Restore")))])]) : _vm._e(), _vm.activeTab === 'archives' ? _c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "margin-right": "8px"
    },
    on: {
      "click": _vm.deletePermanentlyModal
    }
  }, [_c('p', {
    staticClass: "filter",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")])]) : _vm._e()], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": "listing-content"
    }
  }, [_vm.totalPages > 0 ? _c('PaginationNumbers', {
    key: _vm.listingTableUpdater,
    staticClass: "pagination-numbers",
    attrs: {
      "total-pages": _vm.totalPages,
      "total": _vm.totalListings,
      "per-page": _vm.limit,
      "current-page": _vm.currentPage
    },
    on: {
      "pagechanged": _vm.onPageChange
    }
  }) : _vm._e(), _vm.listings ? _c('Content', {
    key: _vm.contentUpdater,
    attrs: {
      "listing-table-updater": _vm.listingTableUpdater,
      "selected-listings-list": _vm.selectedListingsList,
      "total-listings": _vm.totalListings,
      "listings": _vm.listings,
      "conditionsObj": _vm.conditionsObj,
      "statisticsWidgetStatus": _vm.statisticsWidgetStatus,
      "selectedBrokerId": _vm.selectedBrokerId
    },
    on: {
      "selected": _vm.selectedListings,
      "clear-filters": _vm.clearFiltersFromListings,
      "sortListings": function ($event) {
        return _vm.initListings($event);
      },
      "boardLeadsUpdated": function ($event) {
        _vm.boardLeadsUpdater++;
      },
      "widget-status": _vm.changeStatisticsWidgetStatus
    }
  }) : _vm._e(), _vm.totalPages > 0 ? _c('PaginationNumbers', {
    key: _vm.listingTableUpdater + 'updater',
    staticClass: "pagination-numbers",
    style: _vm.selectedListingsList.length > 0 ? 'bottom: -100px' : 'bottom: 0px',
    attrs: {
      "total-pages": _vm.totalPages,
      "total": _vm.totalListings,
      "per-page": _vm.limit,
      "current-page": _vm.currentPage
    },
    on: {
      "pagechanged": _vm.onPageChange
    }
  }) : _vm._e()], 1), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "position": _vm.position,
      "listings": _vm.selectedListingsList,
      "multi": true,
      "selected-listings-list": _vm.selectedListingsList.length
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeListingStatuses,
      "statusesChanged": _vm.changeListingStatuses,
      "open-booking-modal": _vm.openBookingDetailsModal
    }
  }) : _vm._e(), _vm.deleteModalOpen ? _c('ListingConfirmationModal', {
    attrs: {
      "count": _vm.selectedListingsList.length
    },
    on: {
      "canceled": function ($event) {
        _vm.deleteModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteListings();
      }
    }
  }) : _vm._e(), _vm.deletePermanentlyModalOpen ? _c('ListingConfirmationModal', {
    attrs: {
      "count": _vm.selectedListingsList.length
    },
    on: {
      "canceled": function ($event) {
        _vm.deletePermanentlyModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteListingsPermanently();
      }
    }
  }) : _vm._e(), _vm.duplicateModal ? _c('ListingDuplicationModal', {
    attrs: {
      "count": _vm.selectedListingsList.length
    },
    on: {
      "canceled": function ($event) {
        _vm.duplicateModal = false;
      },
      "approved": function ($event) {
        return _vm.duplicateListings();
      }
    }
  }) : _vm._e(), _vm.ownershipModal ? _c('OwnershipConfirmationModal', {
    attrs: {
      "count": _vm.selectedListingsList.length
    },
    on: {
      "canceled": function ($event) {
        return _vm.closePanel();
      },
      "approved": function ($event) {
        return _vm.transferOwnership();
      }
    }
  }) : _vm._e(), _vm.bookingDetailsModalOpen ? _c('BookingDetailsModal', {
    attrs: {
      "bulk-booking": true,
      "listings": _vm.selectedListingsList
    },
    on: {
      "update-listings": _vm.openConfirmationModal,
      "close-modal": function ($event) {
        _vm.bookingDetailsModalOpen = false;
      }
    }
  }) : _vm._e(), _vm.statusModalOpen ? _c('StatusConfirmationModal', {
    attrs: {
      "count": _vm.selectedListingsList.length
    },
    on: {
      "canceled": function ($event) {
        !_vm.statusModalOpen;
      },
      "approved": function ($event) {
        return _vm.changeListingsStatuses();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }