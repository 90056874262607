var render = function render(){
  var _vm$user$access, _vm$user$access$featu, _vm$user$access$featu2, _vm$user$access$featu3, _vm$user$access2, _vm$user$access2$feat, _vm$user$access2$feat2, _vm$user$access2$feat3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropdown"
  }, [_c('button', {
    staticClass: "add-new-button dropbtn",
    staticStyle: {
      "display": "none"
    },
    on: {
      "click": _vm.showDropdown
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/action-add.svg"),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.$t("AddNew")) + " ")]), _c('div', {
    staticClass: "dropdown-content",
    attrs: {
      "id": "myDropdown"
    }
  }, [!_vm.user.config || _vm.user.config && (_vm$user$access = _vm.user.access) !== null && _vm$user$access !== void 0 && (_vm$user$access$featu = _vm$user$access.features) !== null && _vm$user$access$featu !== void 0 && (_vm$user$access$featu2 = _vm$user$access$featu.modules) !== null && _vm$user$access$featu2 !== void 0 && (_vm$user$access$featu3 = _vm$user$access$featu2.projects) !== null && _vm$user$access$featu3 !== void 0 && _vm$user$access$featu3.enabled ? _c('p', {
    on: {
      "click": function ($event) {
        _vm.changeSidepanelStateDeals();
        _vm.showDropdown();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Project")) + " ")]) : _vm._e(), !_vm.user.config || _vm.user.config && (_vm$user$access2 = _vm.user.access) !== null && _vm$user$access2 !== void 0 && (_vm$user$access2$feat = _vm$user$access2.features) !== null && _vm$user$access2$feat !== void 0 && (_vm$user$access2$feat2 = _vm$user$access2$feat.modules) !== null && _vm$user$access2$feat2 !== void 0 && (_vm$user$access2$feat3 = _vm$user$access2$feat2.calendar) !== null && _vm$user$access2$feat3 !== void 0 && _vm$user$access2$feat3.enabled ? _c('p', {
    staticStyle: {
      "color": "gray !important"
    }
  }, [_vm._v(_vm._s(_vm.$t("Task")))]) : _vm._e(), _c('p', {
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'ListingsAdd'
        });
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Listing")))]), _c('p', {
    on: {
      "click": function ($event) {
        _vm.changeClientAddModal();
        _vm.showDropdown();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Contact")) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }